@use '@angular/material' as mat;
@use 'sass:math';

// не знаю сюда их запихнуть? это достаточно top-level?
@import "/src/assets/fonts/Roboto/Roboto.css";
@import "/src/assets/fonts/MaterialIcons/material-design-icons-no-codepoints.css";

@import 'variables';
@import 'colors';
@import 'mixins';
// Нужна для материаловского календаря
@import 'node_modules/gramli-angular-notifier/styles';
//@import '@angular/material/core/theming/theming';
@import './src/app/modules/calendar/calendar-style';
// Plus imports for other components in your app.

@import '@ctrl/ngx-emoji-mart/picker';
// for emoji picker default styling
@import 'overlayscrollbars/styles/overlayscrollbars.css';

@import 'accordion';
@import 'select';
@import 'tooltips';
@import 'checkboxes';
@import 'chips';
@import 'mat-menu';
@import 'sidebar-cards';
@import 'more-menu';
@import 'buttons';
@import 'inputs';
@import 'dialog';
@import 'paginator';
@import 'onboarding';
@import 'datepicker';
@import 'progress-bar';
@import 'radio-button';
@import 'button-toggle-group';
@import 'emoji';
@import 'academy-accordion';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css filfe for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.m2-define-palette(mat.$m2-blue-palette);
$candy-app-accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);
$candy-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.m2-define-light-theme(
                $candy-app-primary,
                $candy-app-accent,
                $candy-app-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import-trello and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);

///** переменные для англуяра
// * пока помещу сюда, потом можем перенести в отдельные файлы
// */
:root {
  // app
  --mat-app-on-primary: #{$color-white};
  --mat-app-primary: #{$color-blue};

  // buttons
  --mdc-filled-button-label-text-color: #{$color-white};
  --mdc-filled-button-container-color: #{$color-blue};

  // letter-spacing
  --mdc-outlined-button-label-text-tracking: normal;
  --mat-app-label-large-tracking: normal;
  --mat-select-trigger-text-tracking: normal;
  --mat-app-title-small-tracking: normal;

  --mdc-filled-text-field-container-color: #{$color-light-grey-40};

  // tabs
  .mat-mdc-tab-header {
    --mat-tab-header-label-text-tracking: normal;
    --mat-tab-header-label-text-weight: 500;
  }
  .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
    --mat-tab-header-inactive-label-text-color: #{$color-grey-100};
    --mat-tab-header-active-label-text-color: #{$color-main-text};
  }

  // menu
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

.mat-mdc-tab:hover .mdc-tab__ripple::before {
  opacity: 0 !important;
}

.notifier__container ul {
  margin: 0;
  padding: 0;
  @include font-stack-main;
}

body,
html {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 100%;
}

@import 'accordion';
@import 'select';
@import 'tooltips';
@import 'checkboxes';
@import 'chips';
@import 'mat-menu';
@import 'sidebar-cards';
@import 'more-menu';
@import 'buttons';
@import 'inputs';
@import 'dialog';
@import 'paginator';
@import 'onboarding';
@import 'datepicker';
@import 'progress-bar';
@import 'radio-button';
@import 'icons';

.txt {
  letter-spacing: normal;
  font-weight: normal;
}

.mat-badge-medium .mat-badge-content {
  width: 15px;
  height: 15px;
  font-size: 10px;
  line-height: 16px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  top: -6px;
  right: -6px;
  line-height: 16px;
}

.mat-mdc-dialog-container {
  padding: 0 !important;
}

.custom-dialog-container .mat-mdc-dialog-container {
  box-shadow: $box-shadow3;
}

.custom-dialog-container-invite .mat-mdc-dialog-container {
  box-shadow: $box-shadow3;
  border-radius: 8px;
}

.mat-mdc-tab-group {
  &.task-history {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;

        .mat-mdc-tab-header {
          margin: 0 8px;
        }
    }

  .content__inner {
    padding: 0 !important;
  }

  &._user {
    .mat-mdc-tab-header {
      margin-top: 12px;
      padding: 0 20px;
    }
  }
}

.mat-mdc-tab-label-container {
  //background-color: $color-bg-gray;
  padding: 0 20px;
}

.questions {
  .mat-mdc-tab-label + .mat-mdc-tab-label {
    margin-left: 20px;
  }
}

.mat-mdc-tab {
  padding: 0 !important;
  min-width: 0 !important;

  & + & {
    margin-left: 30px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-active {
  color: $color-blue;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-group,
.mat-mdc-tab-body-wrapper {
  flex: 1;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  @include scroll;
  width: 100%;
  overflow-x: hidden !important;
}

.mat-mdc-menu-panel.menu {
  max-width: 373px;
  min-height: auto;
  @include scroll;
  overflow-x: hidden;
}

.mat-mdc-select-panel {
  @include scroll;
}

// TODO Убрать
.mat-mdc-checkbox.mat-accent {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-frame {
    border-color: $color-grey-68;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: $color-blue;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  &.mat-checkbox-disabled .mat-checkbox-frame {
    border-color: $color-grey-40;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  &.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-frame {
    border-color: transparent;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  &.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
    background-color: $color-grey-40;
  }
}

@import 'radius-theme';

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle {
  color: $color-main-text-grey;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &.mat-button-toggle-checked {
    color: $color-main-text;
    background-color: $color-blue-hover-light;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &.mat-button-toggle-disabled {
    background-color: $color-white;
    color: $color-grey-40;
  }

  &.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: $color-blue-hover-light;
  }
}




.section {
  .mat-expansion-panel {
    margin-bottom: 0;
    box-shadow: none!important;

    .mat-expansion-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      /* margin-bottom: 8px; */
      align-self: center;
      box-sizing: border-box;
      margin-right: 8px;
      height: 100%;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  .mat-expansion-panel-header-title {
    white-space: break-spaces;
  }

  .mat-expansion-panel-header {
    padding: 0;

    &:hover {
      background-color: transparent !important;
    }
  }

  .mat-expansion-panel-header {
    .mat-content {
      justify-content: space-between;
      overflow: visible;
    }

    //.mat-expansion-indicator {
    //  margin-right: 12px;
    //  margin-bottom: 10px;
    //}
  }
}

.dialog-form__accordion {
  .mat-expansion-panel {
    .mat-expansion-indicator {
      display: none;
    }
  }
}

.dialog-form__accordion {
  .mat-expansion-panel {
    .mat-expansion-indicator {
      display: none;
    }
  }
}

.kit {
  .mat-ink-bar {
    height: 3px;
    color: $color-blue-default;
  }

  .mat-mdc-tab-header {
    border: none;
    background-color: transparent;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-container {
      border-bottom: 1px solid $color-light-grey-68;
      background-color: transparent;
      padding: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label {
      opacity: 1;
      background-color: transparent !important;
      color: $color-grey-100;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-active {
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label-content {
        color: $color-main-text;
      }
    }
  }
}

// Чудовищный костыль для иконок чата
.icon._chats_blue .mat-mdc-icon-button {
  .mat-icon.icon {
    svg {
      path {
        fill: none !important;
      }

      circle {
        stroke: $color-blue !important;
      }
    }
  }
}

.cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

.mat-expansion-panel.subdivision .mat-expansion-panel-body {
  padding: 0;
}

.mat-expansion-indicator {
  width: 18px;
}

.mat-mdc-checkbox._crop {
  overflow: hidden;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    max-width: 100%;
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// for matrix and motivations
.user-list-item.owner._matrix {
  .member__title,
  .member__position {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.accordion._cabinet-boards {
  .mat-expansion-panel
  .mat-expansion-panel-header.cdk-keyboard-focused:not(
            [aria-disabled='true']
        ),
  .mat-expansion-panel
  .mat-expansion-panel-header.cdk-program-focused:not(
            [aria-disabled='true']
        ),
  .mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background-color: transparent;
  }

  .mat-expansion-panel-body {
    margin-top: -20px;
  }
}

.table._cabinet-boards {
  .right {
    .mat-sort-header-container {
      justify-content: flex-end;
    }

    .mat-sort-header-content {
      text-align: right;
    }
  }

  .mat-sort-header-content {
    line-height: 16px;
    text-align: left;
  }

  th.mat-mdc-header-cell {
    vertical-align: bottom;
    padding-bottom: 12px;
  }

  tr.mat-mdc-header-row {
    height: 40px;
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 0;
  }

  td {
    border: none;
  }

  tbody tr {
    height: 36px;

    &:hover {
      background-color: $color-light-grey-24;
    }
  }
}

.table._matrix-inner {
  table-layout: fixed;

  td,
  th {
    position: relative;
    padding: 6px;
    font-size: 14px;
    line-height: 20px;
  }

  td {
    text-align: right;
    white-space: nowrap;
  }

  th {
    vertical-align: bottom;
    background: rgba(215, 223, 233, 0.1);

    /* Grey/68 */

    color: rgba(126, 133, 142, 0.68);
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    overflow: hidden;
    /* or 117% */

    text-align: right;
  }

  th .txt {
    text-align: left;
  }

  td._task-name {
    .txt {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      visibility: visible;
      white-space: normal;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      overflow-wrap: break-word;
      overflow-wrap: anywhere;
    }
  }

  td:not(._task-name) .txt {
    white-space: nowrap;
  }

  td .input {
    margin: 0;
  }

  .delete {
    display: flex;
    position: relative;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    opacity: 0;

    width: 44px;
    height: 80%;

    svg path {
      fill: $color-grey-100;
      color: $color-grey-100;
    }

    &._permanent {
      opacity: 1;
      background: transparent;
    }
  }

  tr {
    cursor: pointer;
  }

  tr:hover .delete {
    opacity: 1;
  }


  th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
    padding-left: 24px;
    //outline: 1px solid red;
    //width: var(--var-column-width);
    box-sizing: border-box;
  }


  th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type {
    padding-left: 24px;
    max-width: 800px;
    width: 800px;
    //background: cadetblue !important;
    box-sizing: border-box;
  }

  th {
    min-width: 109px;
  }

  th.mat-column-cost {
    min-width: 99px;
  }

  th.mat-column-persent {
    min-width: 86px;
  }

  th.mat-column-taskWeight {
    min-width: 45px;
  }

  th._delete {
    width: 40px;
  }
}


.table._matrix {
  table-layout: fixed;

  th {
    vertical-align: bottom;
    padding-bottom: 8px;

    &._delete {
      width: 48px;
    }

    &._num {
      width: 38px;
    }

    &._weight {
      width: 48px;
    }

    &._task-name {
      width: 200px;
    }

    .txt {
      color: $color-grey-100;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
    }
  }

  mat-row,
  mat-header-row,
  mat-footer-row,
  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    border-bottom-color: $color-light-grey-68;
  }
}

.article .article-image {
  img {
    max-width: 100%;
  }
}

.users-oversize {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $color-button-blue;
  width: 40px;
  height: 40px;
  color: $color-blue;
  font-weight: 500;
  font-size: 14px;
}

.custom-slider {
  &.ngx-slider .ngx-slider-pointer {
    top: -6px;
    z-index: 3;
    cursor: pointer;
    border-radius: 16px;
    background-color: $color-blue-default;
    width: 16px;
    height: 16px;

    &:after {
      display: none;
    }
  }

  &.ngx-slider .ngx-slider-bar {
    top: 15px;
    left: 0;
    background-color: $color-grey-40-no-alpha;
    width: 98%;
    height: 6px;
  }

  &.ngx-slider .ngx-slider-selection {
    visibility: visible;
    background-color: $color-blue-default;
  }

  &.ngx-slider .ngx-slider-tick {
    margin-left: 0;
    background-color: $color-grey-40-no-alpha;
  }

  &.ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background-color: $color-blue-default;
  }

  &.ngx-slider .ngx-slider-tick-value {
    display: none;
  }

  &.ngx-slider.with-legend {
    margin-top: 22px;
    margin-bottom: 40px;
  }

  &.ngx-slider .ngx-slider-tick-legend {
    top: 18px;

    /* Grey / 100 */
    color: $color-grey-100;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    letter-spacing: 0.2px;
  }
}

/* hide arrows for input[type=number] */
input:not(.spin)::-webkit-outer-spin-button,
input:not(.spin)::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input:not(.spin)[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

// стилизуем радио батоны
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-group.align-top .mat-radio-label {
  align-items: flex-start;
}

.section._fileupload {
  display: block;

  .title {
    margin: 0;
    color: $color-txt;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0.1px;
  }

  .mat-expansion-panel {
    box-shadow: none;

    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  .mat-expansion-panel-header {
    padding: 0;

    &:hover {
      background-color: transparent !important;
    }
  }

  .mat-expansion-panel-header {
    .mat-content {
      justify-content: space-between;
      overflow: visible;
    }

    .mat-expansion-indicator {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-arrow {
  color: $color-grey-100 !important;
}

// типа селект, но на самом деле это инпут с автокомплитом
mat-form-field.select {
  border-radius: 4px;
  height: 36px;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    margin: 0;
    border: 0;
    padding: 0;
    font-size: 14px;
    line-height: 36px;

    input {
      box-sizing: border-box;
      border-radius: 4px;
      background: #eef2f6;
      padding: 0 13px !important;

      &:focus {
        outline: 2px solid $color-blue-default;
        background: $color-white;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-suffix {
    position: absolute;
    top: 6px;
    right: 8px;
  }

  &.ng-invalid input {
    outline: 2px solid $color-error;
  }
}

//.matrix-list-subdivision {
//  &:first-child .header {
//    border-top-left-radius: 12px;
//    border-top-right-radius: 12px;
//  }
//
//  &:last-child .header {
//    border-bottom-left-radius: 12px;
//    border-bottom-right-radius: 12px;
//  }
//}

.border-radius-8 .mat-mdc-dialog-container {
  border-radius: 8px;
}

.collapsed-panel {
  display: none !important;
}

.collapsed-calendar {
  .cal-events-row {
    display: none;
  }

  .collapsed-panel {
    display: flex !important;
    top: 37px;
    background: linear-gradient(
                    0deg,
                    rgba(215, 223, 233, 0.24),
                    rgba(215, 223, 233, 0.24)
    ),
    #ffffff;

    .cal-header {
      min-height: 37px;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.option.multiline .mat-option-text {
  display: flex;
  position: relative;
  top: 2px;
  overflow: unset;
}

.offset-menu {
  margin-top: 10px;
}

.support-menu {
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple):not(
            .mat-option-disabled
        ) {
    background: transparent;
  }
}

.cdk-global-scrollblock {
  overflow-y: auto;
}

.task-description {
  .ck-sticky-panel__content,
  .ck-sticky-panel__content_sticky {
    width: 100% !important;
    height: auto !important;
  }

  &._short {
    .ck-editor__top {
      display: none;
    }

    .ck.ck-editor__editable_inline {
      border: none !important;
      padding: 0;
    }
  }

  .ck.ck-editor__main > .ck-content {
    @include font-stack-main();
    color: #181c3c;
    font-size: 14px;
    line-height: 20px;

    blockquote {
      box-shadow: 0 0px 6px -4px $color-blue-default;
      border-left: 3px solid $color-blue-default;
      background: $color-blue-light;
      padding-left: 16px;
    }

    mark {
      &.marker-green {
        background-color: $color-green-bg-tag-dark;
      }

      &.marker-yellow {
        background-color: $color-yellow-marker;
      }

      &.marker-pink {
        background-color: $color-error-soft;
      }

      &.marker-blue {
        background-color: $color-blue-marker;
      }

      &.pen-red {
        background-color: transparent;
        color: $color-error;
      }

      &.pen-green {
        background-color: transparent;
        color: $color-green;
      }
    }
  }

  .ck.ck-editor__main>.ck-editor__editable {
    //& > p {
    //  margin-top: 0;
    //  margin-bottom: 0;
    //}
    background: transparent !important;
  }

  .ck.ck-editor__main > .ck-editor__editable.ck-focused {
    & > p {
      &:first-child {
        margin-top: var(--ck-spacing-large);
      }

      &:last-child {
        margin-bottom: var(--ck-spacing-large);
      }
    }

    margin-bottom: -2px !important;
    border: 2px solid $color-blue;
    border-radius: 4px;
  }

  .ck.ck-toolbar {
    position: sticky;
    margin-top: 16px;
  }

  &.hidden {
    .ck {
      display: none;
    }
  }
}

.kit ckeditor + .textarea-wrap__emul {
  padding: 0;
}

.editor-container:not(:has(.ck-focused)) {
  margin-top: 8px !important;

  &:hover {
    background-color: transparent;
  }
}
.ck .ck-sticky-panel .ck-sticky-panel__content_sticky {
  position: static !important;
}

.editor-container {
  &:has(.ck-focused) .textarea-wrap__emul {
      display: none !important;
  }
  &:has(.ck-focused) .ck.ck-editor__main {
    background: white !important;
  }

  ._short {
    .ck.ck-editor__editable_inline:not(.ck-focused) > :first-child {
      margin-top: 0 !important;
    }
  }

}

.editor-content {
  @include font-stack-main();
  color: #181c3c;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;

  :first-child {
    margin-top: 0 !important;
  }

  .marker-green {
    background-color: $color-green-bg-tag-dark;
  }

  .marker-yellow {
    background-color: $color-yellow-marker;
  }

  .marker-pink {
    background-color: $color-error-soft;
  }

  .marker-blue {
    background-color: $color-blue-marker;
  }

  .pen-red {
    background-color: transparent;
    color: $color-error;
  }

  .pen-green {
    background-color: transparent;
    color: $color-green;
  }
}

.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-powered-by-balloon {
  display: none;
}

.cdk-virtual-scroll-viewport._horizontal-scrollable {
  contain: unset;

  .cdk-virtual-scroll-content-wrapper {
    position: relative;
    contain: unset;
    width: max-content;
    max-width: unset;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-container._black {
  cursor: pointer;
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-outer-circle {
    box-sizing: border-box;
    border-color: $color-main-text-grey !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  &.mat-radio-checked {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-inner-circle {
      background-color: $color-main-text-grey;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-container._blue {
  cursor: pointer;
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-outer-circle {
    box-sizing: border-box;
    border-color: $color-blue-default !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  &.mat-radio-checked {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-inner-circle {
      background-color: $color-blue-default;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-panel-wrap .green-opt {
  color: $color-success;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-mdc-option._justify .mat-option-text {
  display: flex;
  justify-content: space-between;
}

.mat-mdc-dialog-container {
  border-radius: 8px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-mdc-checkbox._black.mat-accent.mat-checkbox-checked .mat-checkbox-background {
  background-color: $color-main-text-grey;
}

.mat-mdc-option {
  letter-spacing: normal !important;
  font-size: 14px !important;
  min-height: 36px !important;
}

.pseudo-checkmark-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-mdc-option._option36,
.mat-mdc-menu-item._option36 {
  height: 36px;
  line-height: 36px;
}

.ck-content {
  margin-bottom: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-option-text {
  text-align: left !important;
}

.editor-content p {
  margin-top: 0;
  margin-bottom: 0;
}

.ck-content p {
  margin-top: 0;
  margin-bottom: 0;

  &:first-child {
    margin-top: 1em;
  }

  &:last-child {
    margin-bottom: 1em;
  }
}

[data-overlayscrollbars-padding],
[data-overlayscrollbars-viewport] {
  position: static;
}

.os-scrollbar .os-scrollbar-handle {
  background: $color-grey-40;
}

.os-scrollbar-vertical {
  width: 9px;
}

.cdk-overlay-container {
  z-index: 10000000000;
}

.cdk-virtual-scroll-spacer {
  display: none;
}

.mat-icon {
  flex-shrink: 0 !important;
  overflow: visible !important;
}

.notifier__notification {
  z-index: 10000000001;
}

.custom-color-picker {
  .sketch-fields-container,
  .sketch-swatches-container,
  .sketch-color,
  .photoshop-head,
  .photoshop-controls {
    display: none !important;
  }

  .sketch-picker {
    box-shadow: none !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .photoshop-body {
    //padding: 0 !important;
    box-shadow: none !important;

    padding: 0 !important;
    width: 100% !important;
  }

  .photoshop-picker {
    box-shadow: none !important;
    border-radius: 0 !important;
    background: transparent !important;
    width: 100% !important;
  }

  .photoshop-saturation {
    width: 200px !important;
  }

  .photoshop-hue {
    width: 16px !important;
  }

  .photoshop-hue,
  .photoshop-saturation {
    border: none !important;
    height: 130px !important;
  }
}

.cal-task-menu {
  @include font-stack-main;
  min-height: initial !important;

  .mat-mdc-menu-content {
    padding: 4px !important;
    //max-width: 200px;
  }
}

.mat-mdc-card-content {
  p {
    margin: 0;

    img {
      max-width: 100%;
    }
  }

  figure.image {
    max-width: 100%;

    img {
      max-width: 100%;
    }
  }
}

.task-item {
  .cdk-drag-placeholder {
    display: none !important;
    height: 0 !important;
  }
}

.inn-option {
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-option-text {
    display: flex;
    justify-content: space-between;
  }

  .option__name {
    flex: 1;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .option__inn {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 8px;
    color: $color-grey-100;
  }
}

.tabs._no-header {
  .mat-mdc-tab-header {
    display: none;
  }
}

.company-menu {
  @include font-stack-main();
  min-width: 360px !important;
}

.search._calendar-search {
  .slide-in-blurred-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 260px;
  }

  //.search-button:hover {
  //  background-color: $color-light-grey-24;
  //  border-radius: 4px;
  //}
}

.expansion-panel--no-padding {
  .mat-expansion-panel-header {
    box-sizing: border-box;
    padding: 0;
    width: 100%;
  }

  .mat-expansion-indicator {
    margin-right: 0 !important;
  }
}

.mat-ink-bar {
  border-radius: 2px 2px 0 0;
}

.chat-room {
  .link {
    color: $color-dark-grey-text;
    text-decoration: none;
  }

  .link._tag {
    margin-right: 4px;
    color: $color-blue-default;
    text-decoration: none;
  }
}

.chat {
  .link {
    cursor: pointer;
    color: $color-blue-default;
    text-decoration: underline;
  }

  .link._tag {
    color: $color-blue-default !important;
    text-decoration: none;
  }
}

.mat-divider {
  border-top-color: $color-light-grey-68 !important;
}

.mat-mdc-menu-panel {
  border: 1px solid $color-light-grey-68;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $color-blue-default !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-ripple {
  display: none;
}

.kit .button._icon:active,
.kit .button._icon:active .mat-icon {
  border-radius: 4px;
  background-color: $color-light-grey-40 !important;
  color: $color-grey-100 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu-padding-16 {
  .mat-mdc-menu-content:not(:empty) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.mat-mdc-tooltip._white-tooltip {
  .mdc-tooltip__surface {
    color: $color-main-text-grey !important;
    background: white;
    border: 1px solid $color-light-grey-68;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 0;
    max-width: 250px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}

.label {
  .mat-datepicker-toggle .mat-mdc-button-base {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 36px;
    height: 36px;
    font-size: 16px;
    line-height: 36px;

    &:disabled {
      color: #00000042;
    }
  }
}

.partners-form {
  .partners-email-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-outline {
      border-radius: 5px;
      background: $color-white;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}

.checkbox-ellipsis {
  min-width: 0;
  flex: 1;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  & > .mat-checkbox-layout {
    width: 100%;
    flex: 1;
    display: flex;
    min-width: 0;
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    & > .mat-checkbox-label {
      width: 100%;
      flex: 1;
      display: flex;
      min-width: 0;
      @include ellipsis;
    }
  }
}

.ranges-checkbox {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    width: 100%;
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-label {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;

      font-size: 14px;
      line-height: 20px;

      color: $color-dark-grey-text;
    }
  }
}

.confirmation-dialog {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    padding: 24px !important;
  }
}

.modal-assigment-test-users-select {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    border: 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-trigger {
    padding: 0 6px;
  }
}

.modal-assignment-test-progress-bar mat-progress-bar {
  width: 100% !important;
}

.modal-assignment-test-users-rating-block .rating-block {
  padding: 9px 0;
}

.popover-content {
  position: absolute;
  z-index: 999;
}

.datepicker-toggle-36 {
  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    position: absolute;
    top: 50%;
    height: 36px;
    left: 50%;
    width: 36px;
    transform: translate(-50%, -50%);
  }
}

.small-select {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    color: var(--Main-Main-text, #181C3C);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.2px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-trigger {
    height: 24px;
    padding: 2px 8px;
  }
}

.mat-mdc-option.select-icon {
    .selected-icon {
        color: $color-blue-default;
        width: 24px;
        height: 24px;
        align-self: center;
        margin-left: 8px;
        margin-right: 0px;
        visibility: hidden;
    }

  &.mat-mdc-selected {
    background: transparent !important;

    .selected-icon {
      visibility: visible;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-trigger {
        height: 24px;
        padding: 2px 8px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.04) !important;
    }
  }
}

.mat-mdc-option.flex-option {
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-option-text {
    display: flex;
  }
}

.chats-inner {
  .date-box.float {
    visibility: hidden;
    //opacity: 0;
    //transition: visibility 0.2s, opacity 0.2s ease-in-out;

    .date {
      .date {
        background-color: $color-white;
        border-radius: 24px;
        box-shadow: $box-shadow1;
      }
    }
  }
}

//в чатах, если отображается плавающая поверх дата
.chats-inner._float-date-visible {
  // самый верхний элемент видимый на экране в данное время (активный, после него неактивный)
  .chat.active:has( + .chat.inactive.top) {
    .date-box.float {
      visibility: visible;
      opacity: 1;
    }
  }
}

.radius-theme.sticky-tabs {
  .mat-mdc-tab-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 9;

  }
}

.task-members {
  .mat-mdc-tab-body-wrapper {
    box-sizing: border-box;
    border-bottom: 1px solid rgba(215, 223, 233, .68);

    .member__img {
      margin-top: 4px;
    }
  }

  .mat-mdc-tab-body {
    padding: 16px 16px 6px !important;
  }

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-academy {
  //margin-top: 30px !important;
}

.custom-indicator .mat-expansion-indicator {
  border-radius: 4px;
  background-color: #d7dfe93d;
  width: 36px;
  height: 36px !important;
}

.color-dark-grey-text {
  color: $color-dark-grey-text;
}

.margin-zero {
  margin: 0;
}

.text-center {
  text-align: center;
}

.text-400-14-20-15 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.icon-double-arrow {
  color: white;
  background-color: $color-blue;
  border-radius: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.table-select_disabled .mat-select-arrow {
  color: #7E858E66 !important;
}

.search-list__item {
  .task__description-main {
    line-height: 19px;
  }

  .planing-task-item__box {
    padding: 4px 16px 4px !important;

    &._no-boards {
      min-height: 28px !important;
    }
  }

  .task__description-board {
    flex-shrink: 1;
    flex-grow: 0;
    min-width: 0;
    margin-top: 4px;

    .txt {
      max-width: unset !important;
    }
  }
}

.cal-highlight-drag {
 .cal-event {
   background-color: currentColor !important;
   box-shadow: $box-shadow3;
 }

  .icons {
    background-color: currentColor !important;
  }
}

.left__content_learn .rating-block__custom-padding .rating-block__padding {
    padding: 0;

    @media (min-width: 1920px) {
      padding: $padding-indicator;
    }
}

.custom-indicator .mat-expansion-indicator {
    border-radius: 4px;
    background-color: #d7dfe93d;
    width: 36px;
    height: 36px !important;
}

.color-dark-grey-text {
    color: $color-dark-grey-text;
}

.margin-zero {
    margin: 0;
}

.text-center {
    text-align: center;
}

.text-400-14-20-15 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
}

.icon-double-arrow {
    color: white;
    background-color: $color-blue;
    border-radius: 4px;
}


.table-select_disabled .mat-select-arrow {
  color: #7E858E66 !important;
}

.menu-checkbox {
  width: 100%;
  height: 100%;
  align-items: center;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    width: 100%;
    height: 100%;

    align-items: center;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-label {
    flex: 1;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.tab-fix .mat-tab-label-content {
  padding: 0 4px;
}

.table._cabinet-boards._fixed-header {
  thead {
    position: sticky;
    background: white;
    z-index: 10;
    top: 45px;
  }
}

.popover-content.bs-popover-left {
  translate: 0 -50%;
}

.custom-radio-buttons {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-outer-circle {
    width: 18px;
    height: 18px;
    left: 1px;
    top: 1px;
    background: white;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-inner-circle {
    width: 18px;
    height: 18px;
    left: 1px;
    top: 1px;
  }
}

.expansion-panel-collapse {
  .mat-expansion-panel-content {
    visibility: collapse !important;
    overflow: hidden;
  }

  &.mat-expanded {
    .mat-expansion-panel-content {
      visibility: visible !important;
      overflow: visible !important
    }
  }
}

tr._active.highlight-actiev, tr._active .fixed {
  background-color: $color-light-grey-24-no-alpha;
}

.mat-mdc-button>.mat-icon, .mat-mdc-unelevated-button>.mat-icon, .mat-mdc-raised-button>.mat-icon, .mat-mdc-outlined-button>.mat-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.mdc-icon-button {
  font-size: 14px !important;
  white-space: nowrap;
}

.date-time-group .mat-mdc-form-field {
  .mdc-text-field--filled {
    border-radius: 4px !important;
  }

  .mat-mdc-form-field-flex {
    height: 100%;
  }

  .mat-mdc-form-field-flex {
    align-items: center;
  }

  .mat-mdc-autocomplete-trigger {
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-menu-item-text {
  color: $color-main-text;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.option.multiline .mdc-list-item__primary-text {
  display: flex;
  overflow: unset;
  padding: 4px 0;
  align-items: center;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  box-shadow: $box-shadow3;
}

.radius-theme {
  .mat-mdc-tab-body-content {
    padding: 0 6px;
    overflow-x: hidden;
  }
}

.mdc-switch__icon {
  display: none;
}

.mat-button-toggle-button {
  justify-content: center !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  text-align: center !important;
}


.board {
  .mat-mdc-radio-button .mdc-radio__background::before {
    display: none !important;
  }
}

.user__header {
  .mat-mdc-icon-button img, .mat-mdc-icon-button svg {
    width: initial !important;
    height: initial !important;
    vertical-align: unset !important;
  }
}

.mat-mdc-radio-button._black {
  color: #4a586d;
}

.mat-mdc-radio-button._blue {
  color: $color-blue-default;
}


.mdc-switch__shadow {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  background-color: #fafafa;
}
 // .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before
.mdc-switch--unselected .mdc-switch__track::before,
.mdc-switch--unselected.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before,
.mdc-switch--unselected.mdc-switch:enabled:active .mdc-switch__track::before,
.mdc-switch--unselected.mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before {
  background-color: #9e9e9e;
}

.mat-mdc-slide-toggle-checked {
  .mdc-switch__shadow {
    background-color: $color-blue-default;
 }

  .mdc-switch__track {
    background-color: #9bc0ff;
  }
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step {
  border: 0;
  border-radius: 8px;
}

.mat-mdc-menu-panel.tour-step {
  margin: 10px;
}

.mat-mdc-option {
  .pseudo-checkmark-wrap {
    display: none;
  }
}

.mat-mdc-menu-content .mat-mdc-option .mat-pseudo-checkbox-minimal {
   display: none;
 }

.ck .ck-placeholder:before, .ck.ck-placeholder:before {
  color: $color-grey-68 !important;
  cursor: text;
}
.org-diagramm {
  .item-title {
    overflow: hidden !important;
  }

  .item-frame {

  }

  .photo {
    object-fit: cover;
  }
}

.mirror-pointer-events a {
  pointer-events: auto;
  cursor: pointer;
}

.editor-container:hover:not(:focus) .textarea-wrap__emul {
  background: $color-light-grey-24;
}

.history-wrapper {
  .mat-mdc-tab-label-container {
    border-bottom: 1px solid rgba(215, 223, 233, 0.68);
  }

  .mdc-tab--active .mdc-tab__text-label {
    color: $color-main-text !important;
    font-weight: 500;
  }

}

.greyed-input {
  transition: all 0.1s ease-in;

  .label__txt {
    transition: all 0.1s ease-in;
  }

  &:not(:has(textarea:focus, input:focus, .filled)) {
    background-color: $color-light-grey-24;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;

    height: 56px;
    box-sizing: border-box;
    cursor: pointer;

    .label__txt {
      font-size: 14px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.15px;

      transform: translate(16px, 12px);

      &, .icon {
        color: $color-grey-68 !important;

        svg {
          path, rect, circle {
            fill: $color-grey-68 !important;
          }
        }
      }
    }

    input, textarea {
      position: absolute;
      top: -9999px;
      background-color: transparent;
      color: transparent;
      border: none;
      max-height: 1000px;
    }

    .textarea-wrap__emul {
      display: none;
    }

    &:hover {
      background-color: $color-light-grey-40;
    }
  }
}
